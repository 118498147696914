import { useCallback } from "react";

export function useLocalStorage() {
  const get = useCallback((key) => {
    const isServer = typeof window === "undefined";
    if (!isServer) return JSON.parse(localStorage.getItem(key))?.value ?? null;
    else return null;
  }, []);

  const save = useCallback((key, value) => {
    const isServer = typeof window === "undefined";

    if (!isServer) localStorage.setItem(key, JSON.stringify({ value }));
  }, []);

  return {
    get,
    save,
  };
}