import { useCallback, useEffect, useMemo, useState } from "react";
import CheckoutProgressbar from "@components/checkout/checkoutProgressbar";
import { StepDisplay } from "@components/assistant/assistantFlow/StepDisplay";
import router from "next/router";
import { useQuery, useMutation } from "@apollo/client";
import { UpdateAssistantSession } from "src/graphql/mutations/UpdateAssistantSession";
import { LastSession } from "src/graphql/query/session";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { Results } from "../results";
import { DimensionsInputs } from "./DimensionsInputs";
import { mergeValues } from "@utils/method";

export const AssistantFlow = ({ step, space, data, loading }) => {
  const { get } = useLocalStorage();
  const [reference] = useState(get("sanmarino-device-id"));

  const {
    data: sessionData,
    loading: sessionLoading,
    error,
    refetch,
  } = useQuery(LastSession, {
    variables: { reference },
    fetchPolicy: "network-only",
  });


  // const [roomDimensions, setRoomDimensions] = useState({floor: null, wall: null})
  const [currentSession, setCurrentSession] = useState(null);

  const currentSubstep = useMemo(() => {
    if (currentSession) return currentSession.substep;
    else return 0;
  }, [currentSession]);


  const progress = useMemo(() => {
    return [...data.map((s) => s.name), "Dimensiones", "Resultado"];
  }, [data]);

  //redirect if error
  useEffect(() => {
    if (!sessionLoading) {
      if (sessionData?.assistantSessions?.data?.length) {
        let session = {
          id: sessionData?.assistantSessions?.data[0].id,
          ...sessionData?.assistantSessions?.data[0]?.attributes,
        };
        setCurrentSession(session);
        if (session.step !== step) {
          router.push(`/assistant/${space}/${session.step}`);
        }
      } else {
        router.push("/assistant");
      }
    }
    let session = {
      id: sessionData?.assistantSessions?.data[0].id,
      ...sessionData?.assistantSessions?.data[0]?.attributes,
    };
    setCurrentSession(session);
  }, [sessionData, step]);

  const [updateSession] = useMutation(UpdateAssistantSession);

  const evaluateNextStep = useCallback(
    async (option) => {
      const mergedValues = mergeValues(currentSession.values, option.values);
      const input = { values: mergedValues };
      if (currentSubstep >= data[step].substep.length - 1) {
        input.substep = 0;
        input.step = currentSession.step + 1;
      } else {
        input.substep = currentSession.substep + 1;
      }
      const updatedSessionResult = await updateSession({
        variables: {
          id: sessionData?.assistantSessions?.data[0].id,
          input,
        },
      });

      refetch();
    },
    [data, step, currentSession, currentSubstep]
  );

  const submitDimensions = useCallback(async (dimensions) => {
    const input = {
      floorAreaToCover: dimensions.floor,
      wallAreaToCover: dimensions.wall,
    };
    const updatedSessionResult = await updateSession({
      variables: {
        id: sessionData?.assistantSessions?.data[0].id,
        input,
      },
    });
    refetch();
  });

  return (
    <div className="assistant container">
      <div className="assistant-padding">
        <CheckoutProgressbar
          progressValue={currentSession?.wallAreaToCover &&
            currentSession?.floorAreaToCover ? progress.length - 1 : +step}
          customNodeCount={progress}
        />
      </div>

      {data?.length > step ? (
        <StepDisplay
          step={step}
          data={data[step].substep[currentSubstep]}
          loading={loading}
          evaluateNextStep={evaluateNextStep}
        />
      ) : currentSession?.wallAreaToCover &&
        currentSession?.floorAreaToCover ? (
        <Results
          sessionId={currentSession?.id}
          floorAreaToCover={currentSession?.floorAreaToCover}
          wallAreaToCover={currentSession?.wallAreaToCover}
          sessionPath={ currentSession.path }
        ></Results>
      ) : (
        <DimensionsInputs
          dimensions={{
            floor: currentSession?.floorAreaToCover,
            wall: currentSession?.wallAreaToCover,
          }}
          submitDimensions={submitDimensions}
        />
      )}
    </div>
  );
};
