import React from 'react';

const CongratulationsMessage = ( {path} ) => {

    switch (path) {
        case "bathroom":
            return (<React.Fragment>
                Ya tenemos lista la selección de productos para tu baño ideal
            </React.Fragment>);
        case "cocina":
            return (<React.Fragment>
                Ya tenemos lista la selección de productos para tu cocina ideal
            </React.Fragment>);
        default:
            return (<React.Fragment>
                Ya tenemos lista la selección de productos para tu ambiente ideal
            </React.Fragment>);
      }
  
};

export default CongratulationsMessage;