import Link from "next/link";
import { useContext, useMemo } from "react";
import CartItem from "../../cart/CartItem";
import ResultProductItemNew from "./ResultProductItemNew";
import { CartContext } from "@global/CartContext";
import { formatProducts } from "@utils/formatProducts";

const ResultCartProducts = ({ list = null, replace, remove }) => {
  const { shoppingCart, clearCart } = useContext(CartContext);

  const formattedProductsList = useMemo(
    () => (list ? formatProducts(list, true) : null),
    [list]
  );

  const productsList = useMemo(() => {
    if (formattedProductsList) return formattedProductsList;
    return shoppingCart;
  }, [shoppingCart, formattedProductsList]);

  return (
    <div style={{ width: "100%" }}>
      {productsList.length > 0 &&
        productsList.map((product, index) => (
          <ResultProductItemNew
            key={product.cartId}
            product={product}
            replace={replace}
            replaceIndex={index}
            remove={() => remove(index)}
          />
        ))}
    </div>
  );
};

export default ResultCartProducts;
