import { useState, useEffect } from "react"

export const DimensionsInputs = ({dimensions, submitDimensions}) => {
    const [localWallArea, setLocalWallArea] = useState(dimensions.wall ? `${dimensions.wall}` : '')
    const [localFloorArea, setLocalFloorArea] = useState(dimensions.floor ? `${dimensions.floor}` : '')
    const [length, setLength] = useState("")
    const [width, setWidth] = useState("")
    
    useEffect(() => {
        const inputFloor = document.getElementById("dimension-input-length");
        inputFloor.focus();
    }, []);
    useEffect( () => {
        if ( width && length && width.slice(-1) != '.' && length.slice(-1) != '.' ) {
            setLocalFloorArea(1.1*width*length);
            setLocalWallArea(4.4*(Number(width)+Number(length)));
        } else {
            setLocalFloorArea("");
            setLocalWallArea("");
        }
    }, [width, length])

    const handleFloorWidth = (event) => {
        const num = event.target.value
        if (!isNaN(num) && num.length < 6) {
            setWidth(num);
        } else if (!width) {
            setWidth('');
        }
    }
    const handleFloorLength = (event) => {
        const num = event.target.value
        if (!isNaN(num) && num.length < 6) {
            setLength(num);
        } else if (!length) {
            setLength('');
        }
    }
    
    const handleSubmitDimensions = () => {
        if (localFloorArea && localWallArea) {
            submitDimensions({floor: +localFloorArea, wall: +localWallArea})
        }
    }

    return (
        <div className="assistant-padding">
            <a className="assistant-go-back-wrapper" href="/assistant">
                <img src={"/assets/images/assistant/icons/go-back-arrow.svg"} />{" "}
                Volver a iniciar
            </a>
            <div className="assistant-header-wrapper">
                {/* <div className="assistant-go-back-wrapper" onClick={() => handleGoBackButtonClick()}>
                    <img src={'/assets/images/assistant/icons/go-back-arrow.svg'}/> Atrás
                </div> */}
                <div className="assistant-header-text">
                    <div className="assistant-header">Ya casi estamos!</div>
                    <div className="assistant-subheader">
                        Para terminar, dinos las dimensiones de tu espacio.
                    </div>
                </div>
            </div>
            <div className="dimensions-inputs-row-wrapper">

                <div className="dimension-input-container">
                    <div className="dimension-input-name">Largo del piso</div>
                    <input id="dimension-input-length" type="text" value={length} onChange={(event) => handleFloorLength(event)} className="dimension-input-number"></input>
                    <div className="dimension-input-unit">Metros</div>
                </div>

                <div className="inputs-row-separator">×</div>

                <div className="dimension-input-container">
                    <div className="dimension-input-name">Ancho del piso</div>
                    <input type="text" value={width} onChange={(event) => handleFloorWidth(event)} className="dimension-input-number"></input>
                    <div className="dimension-input-unit">Metros</div>
                </div>

            </div>
            <div className="assistant-center-content">
                <div onClick={handleSubmitDimensions} className={`btn assistant-arrow-btn ${ !(localFloorArea && localWallArea) ? 'assistant-arrow-btn-disabled' : ''}`}>CONTINUAR<img src={'/assets/images/assistant/icons/arrow-right.svg'}/></div>
            </div>
        </div>
    )
}