import { gql } from "@apollo/client"

export const UpdateAssistantSession = gql`
    mutation UpdateCurrentAssistantSession( $id: ID!, $input: AssistantSessionInput! ) {
        updateAssistantSession(id: $id, data: $input) 
        {
            data {
                attributes {
                    values
                    finished
                    path
                    step
                    reference
                    substep
                    wallAreaToCover
                    floorAreaToCover
                }
            }
        }
    }
`