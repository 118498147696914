import { gql } from "@apollo/client";


export const LastSession = gql`
query GetSession( $reference: String!) {
    assistantSessions(
        filters: {reference: {eq: $reference}}, 
        sort: "id:desc",
        pagination: {limit: 1}
    ) {
        data {
        id
        attributes {
            values,
            finished, 
            step,
            path, 
            reference,
            substep,
            wallAreaToCover,
            floorAreaToCover
        }
        }
    }
}`