import { useCallback, useState } from "react";
import { AppConfig } from "src/app-config";

export const StepDisplay = ({ step, data, loading, evaluateNextStep }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleContinueButtonClick = useCallback(() => {
    // aca lo mando para el server con  dispatchSelection()

    evaluateNextStep(selectedOption);
    selectOption(null);
  }, [selectedOption]);

  const selectOption = (option) => {
    console.log("BBBBBBB");

    if (selectedOption?.title === option?.title) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  return (
    <div className="assistant-padding">
      <a className="assistant-go-back-wrapper" href="/assistant">
        <img src={"/assets/images/assistant/icons/go-back-arrow.svg"} />{" "}
        Volver a iniciar
      </a>
      <div className="assistant-header-wrapper">
        <div className="assistant-header-text">
          <div className="assistant-header">
            {!loading ? data?.title : <div>LODING...</div>}
          </div>
          <div className="assistant-subheader">
            {!loading ? data?.description : <div>LOADING...</div>}
          </div>
        </div>
      </div>
      <div className="assistant-center-content">
        <div className="content-options-row">
          {data?.options?.data?.length &&
            data.options.data.map((currentOption, index) => {
              return (
                <div
                  key={currentOption.attributes.title + index}
                  className={`z-10 option-wrapper selectable-option-wrapper ${
                    selectedOption?.title === currentOption.attributes.title
                      ? "selected-option"
                      : ""
                  }`}
                  onClick={() => selectOption(currentOption.attributes)}
                  style={{
                    backgroundImage: `url(${AppConfig.api_url}${currentOption.attributes.pic.data?.attributes.url})`,
                  }}
                >
                  <div className="option-header-wrapper"></div>
                  <div className="step-description-wrapper">
                    <div className="step-description step-description-title">
                      {currentOption.attributes.title}
                    </div>
                  </div>

                  {selectedOption?.title === currentOption.attributes.title && (
                    <div
                      className="btn assistant-arrow-btn z-30"
                      onClick={() => {
                        if (selectedOption) {
                          console.log("HIT")
                          handleContinueButtonClick();
                        }
                      }}
                      style={{
                        backgroundColor: "#00000080",
                        width: "100%",
                        borderRadius: 0,
                        zIndex: 20,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      Continuar
                      <img
                        src={"/assets/images/assistant/icons/arrow-right.svg"}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        {/* <div
          onClick={() => {
            selectedOption ? handleContinueButtonClick() : null;
          }}
          className={`btn assistant-arrow-btn ${
            !selectedOption ? "assistant-arrow-btn-disabled" : ""
          }`}
        >
          CONTINUAR
          <img src={"/assets/images/assistant/icons/arrow-right.svg"} />
        </div> */}
      </div>
    </div>
  );
};
