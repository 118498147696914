import { ResultProductItem } from "./ResultProductItem";
import { gql, useQuery } from "@apollo/client";
import { AppConfig } from "src/app-config";
import { useState, useMemo, useContext, useEffect, useCallback } from "react";
import { CURRENCY } from "@utils/constant";
import ResultCartProducts from "@components/assistant/results/ResultCartProducts";
import { CartContext } from "@global/CartContext";
import { buildProduct } from "@utils/formatProducts";
import { getDiscountPrice, calculateQty } from "@utils/product";
import router from "next/router";
import ReplaceItem from "@components/modal/ReplaceProduct";
import { cloneDeep, replace, result } from "lodash";
import { Modal } from "react-bootstrap";
import CongratulationsMessage from "./CongratulationsMessage";

const assistantQuery = gql`
  query GetAssistantResult($sessionId: Int) {
    assistantSessionResult(sessionId: $sessionId) {
      ranking {
        assistant_result {
          data {
            attributes {
              name
              thumbnail {
                data {
                  attributes {
                    url
                  }
                }
              }
              resultProductWrapper {
                quantity
                resultProductType
                product {
                  data {
                    id
                    attributes {
                      title
                      description
                      presentation
                      price
                      category {
                        data {
                          id
                          attributes {
                            name
                            label
                          }
                        }
                      }
                      sub_category {
                        data {
                          id
                          attributes {
                            name
                            label
                          }
                        }
                      }
                      sku
                      stock
                      unitsper
                      images {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
              values
            }
          }
        }
        score
        attributes
      }
    }
  }
`;

export const Results = ({sessionId, sessionPath, wallAreaToCover, floorAreaToCover }) => {
  const [show, setShow] = useState(false);
  const [showReplace, setShowReplace] = useState(false);
  const [resultIndex, setResultIndex] = useState(0);

  const { removeProduct, addToCart } = useContext(CartContext);

  const { data, loading } = useQuery(assistantQuery, {
    variables: { sessionId: +sessionId },
    fetchPolicy: "network-only",
  });

  const addProductsToCart = (products) => {
    products.forEach((product) => {
      const formattedProd = buildProduct(product.product.data);

      const discountedPrice = getDiscountPrice(
        formattedProd.price,
        formattedProd.discount
      );

      addToCart({
        ...formattedProd,
        price: discountedPrice ? discountedPrice : formattedProd.price,
        color: [],
        size: [],
        material: [],
        quantity: product.quantity,
      });
    });
  };

  const handleAddToCart = (prods) => {
    addProductsToCart(prods);
    router.push("/cart");
  };

  const handleBuyNow = (prods) => {
    addProductsToCart(prods);
    router.push("/checkout");
  };

  const currentDisplayedResult = useMemo(() => {
    return loading
      ? null
      : {
          ...data?.assistantSessionResult.ranking[resultIndex].assistant_result
            .data.attributes,
        };
  }, [data, loading, resultIndex]);

  const [updatedCurrentDisplayedResult, setUpdatedCurrentDisplayedResult] =
    useState(null);

  const displayResults = useMemo(
    () =>
      updatedCurrentDisplayedResult
        ? updatedCurrentDisplayedResult
        : currentDisplayedResult,
    [currentDisplayedResult, updatedCurrentDisplayedResult]
  );

  const handleRemove = useCallback(
    (index) => {
      let newResult = cloneDeep(displayResults);

      newResult.resultProductWrapper.splice(index, 1);

      setUpdatedCurrentDisplayedResult(newResult);
    },
    [displayResults, setUpdatedCurrentDisplayedResult]
  );

  const handleReplace = useCallback(
    (product) => {
      const prodTemplate = {
        data: {
          __typename: "ProductEntity",
          id: product.id,
          attributes: {
            __typename: "Product",
            title: product.name,
            sku: product.sku,
            categories: {
              data: product.categories.map((c) => ({
                attributes: c,
              })),
            },
            description: product.description,
            presentation: product.presentation,
            price: product.price,
            stock: product.stock,
            unitsper: product.unitsper,
            unit: product.unit,
            images: {
              __typename: "UploadFileRelationResponseCollection",
              data: product.thumbs.map((url) => ({
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  url: url.replace(AppConfig.api_url, ""),
                },
              })),
            },
          },
        },
      };

      let newResult = cloneDeep(displayResults);

      newResult.resultProductWrapper[showReplace.replaceIndex] = {
        ...newResult.resultProductWrapper[showReplace.replaceIndex],
        product: prodTemplate,
      };

      const { unit } = prodTemplate.data.attributes;

      if (
        newResult.resultProductWrapper[showReplace.replaceIndex]
          .resultProductType !== "item" &&
        unit !== "M2"
      ) {
        newResult.resultProductWrapper[
          showReplace.replaceIndex
        ].resultProductType = "item";
      }

      setUpdatedCurrentDisplayedResult(newResult);
      setShowReplace(false);
    },
    [displayResults, showReplace]
  );

  const calculateSubtotal = useMemo(() => {
    let res = 0;
    displayResults?.resultProductWrapper.forEach((prod) => {
      let calculatedQuantity = 0;

      if (prod.resultProductType === "item") {
        calculatedQuantity = calculateQty(
          prod.product.data.attributes,
          prod.product.data.attributes.unitsper
        );
      }

      if (prod.resultProductType === "floor") {
        calculatedQuantity = calculateQty(
          prod.product.data.attributes,
          +floorAreaToCover * prod.quantity
        );
      }

      if (prod.resultProductType === "wall") {
        calculatedQuantity = calculateQty(
          prod.product.data.attributes,
          +wallAreaToCover * prod.quantity
        );
      }

      res += calculatedQuantity * prod.product.data.attributes.price;
    });

    return res.toFixed(2);
  }, [displayResults]);

  return (
    <div className="result-container-wrapper">
      <ReplaceItem
        modalShow={showReplace}
        modalOnHide={() => setShowReplace(null)}
        replace={handleReplace}
        category={showReplace?.category}
      />
      <div className="assistant-padding result-information-column">
        <div className="result-information-wrapper">
          <a className="assistant-go-back-wrapper" href="/assistant">
            <img src={"/assets/images/assistant/icons/go-back-arrow.svg"} />{" "}
            Volver a iniciar
          </a>
          <div className="result-information-header-wrapper">
            <div className="result-information-title">Todo listo!</div>

            <div className="result-information-itlsubte">
              <CongratulationsMessage path={sessionPath}></CongratulationsMessage>
            </div>
          </div>
          <div className="result-image-wrapper">
            {currentDisplayedResult?.thumbnail?.data[0]?.attributes?.url ? (
              <>
                <img
                  style={{ width: "100%" }}
                  src={`${AppConfig.api_url}${currentDisplayedResult.thumbnail.data[0].attributes.url}`}
                  onClick={() => setShow(true)}
                />
                <Modal size="lg" show={show} onHide={() => setShow(false)}>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShow(false)}
                  >
                    <span className="icon icon-clear" />
                  </button>
                  <img
                    style={{ maxHeight: "85vh" }}
                    src={`${AppConfig.api_url}${currentDisplayedResult.thumbnail.data[0].attributes.url}`}
                  />
                </Modal>
              </>
            ) : (
              <div className="result-image-spinner">LOADING</div>
            )}
          </div>
          <a href="/assistant" className="result-image-footer">
            Volver a iniciar
          </a>{" "}
          {currentDisplayedResult?.name}
        </div>
      </div>
      <div className="result-cart-column">
        <div
          className="result-cart-header-wrapper"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          Productos
          {updatedCurrentDisplayedResult && (
            <div
              className="cart-undo-changes btn"
              onClick={() => {
                setUpdatedCurrentDisplayedResult(null);
              }}
            >
              Deshacer cambios x
            </div>
          )}
        </div>
        {displayResults ? (
          <ResultCartProducts
            list={{
              products: {
                data: displayResults?.resultProductWrapper.map((prod) => ({
                  id: prod.product.data.id,
                  attributes: {
                    ...prod.product.data.attributes,
                    quantity: calculateQty(
                      prod.product.data.attributes,
                      prod.resultProductType === "floor"
                        ? +floorAreaToCover
                        : prod.resultProductType === "wall"
                        ? +wallAreaToCover
                        : prod.quantity
                    ),
                    priceBox:
                      prod.product.data.attributes.price *
                      prod.product.data.attributes.unitsper,
                    resultProductType: prod.resultProductType,
                  },
                })),
              },
            }}
            replace={(id, category, replaceIndex, unit) =>
              setShowReplace({ id, category, replaceIndex, unit })
            }
            remove={handleRemove}
          />
        ) : (
          <h2>LOADING</h2>
        )}
        <div className="cart-footer">
          <div className="cart-footer-title">
            <div>SUBTOTAL</div>
            <div>
              {CURRENCY} {calculateSubtotal}
            </div>
          </div>

          <div
            className="cart-primary-button btn"
            onClick={() => {
              handleBuyNow(displayResults?.resultProductWrapper);
            }}
          >
            COMPRAR AHORA
          </div>
          <div
            className="cart-secondary-button btn"
            onClick={() => {
              console.log(displayResults?.resultProductWrapper);
              handleAddToCart(displayResults?.resultProductWrapper);
            }}
          >
            AGREGAR AL CARRITO
          </div>
        </div>
      </div>
    </div>
  );
};
