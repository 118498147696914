import PropTypes from "prop-types";
import { useContext } from "react";
import Slider from "react-slick";
import Tooltip from "@components/ui/tooltip";
import { useProduct } from "@hooks";
import { CURRENCY } from "@utils/constant";
import { CartContext } from "@global/CartContext";
import { toCapitalize } from "@utils/toCapitalize";
import { getCartProductQuantity, getProductsByCategory } from "@utils/product";
import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import ProductRatings from "@components/product/elements/ProductRatings";
import ShopGrid from "@components/shop/ShopGrid";
import { ProductsContext } from "@global/ProductsContext";

const ReplaceItem = ({ category = "bath", modalShow, modalOnHide, replace }) => {
  const { products } = useContext(ProductsContext);
  const categoryProducts = getProductsByCategory(products, category, -1);
  return (
    <Modal show={modalShow} onHide={modalOnHide} size="lg">
      <button type="button" className="close" onClick={modalOnHide}>
        <span className="icon icon-clear" />
      </button>

      <ModalBody className="tt-modal-quickview-wrapper">
        <div className="tt-modal-quickview-container">
          <ShopGrid
            sidebar={true}
            sidebarPosition="left"
            containerFluid={false}
            products={categoryProducts}
            replace={replace}
            pageTitle={toCapitalize(category)}
            category={category}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReplaceItem;
