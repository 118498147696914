import Link from "next/link";
import { useContext, Fragment } from "react";
import propType from "prop-types";
import Quantity from "./Quantity";
import { CURRENCY } from "@utils/constant";
import { CartContext } from "@global/CartContext";
import { toCapitalize } from "@utils/toCapitalize";
import { calculateQty } from "@utils/product";

const CartItem = ({ product, className }) => {
  const {
    color,
    thumbs,
    material,
    name,
    slug: slugName,
    price,
    size,
    variations,
  } = product;
  const quantity = calculateQty(product, product.quantity);
  const slug = `/product/${slugName}`;
  const { removeProduct } = useContext(CartContext);

  return (
    <tr className={className}>
      <td>
        <span
          className="tt-btn-close"
          style={{ cursor: "pointer" }}
          onClick={() =>
            removeProduct({
              cartId: product.cartId,
            })
          }
        />
      </td>
      <td>
        <div className="tt-product-img">
          {variations && variations.length ? (
            variations.map(
              (variation, i) =>
                variation.color.name === color && (
                  <Link href={slug} key={i}>
                    <a>
                      <img src={variation.color.thumb} alt={product.name} />
                    </a>
                  </Link>
                )
            )
          ) : (
            <Link href={slug}>
              <a>
                <img src={thumbs[0]} alt={name} />
              </a>
            </Link>
          )}
        </div>
      </td>
      <td>
        <h2 className="tt-title">
          <Link href={slug}>{name}</Link>
          {!!(variations && variations.length) && (
            <ul className="tt-add-info">
              <li>
                {`
                                ${toCapitalize(color)},
                                ${size.toUpperCase()},
                                ${toCapitalize(material)}
                            `}
              </li>
            </ul>
          )}

          <ul className="tt-add-info">
            <li>
              {product.presentation}{" "}
              {product.unitsper !== 1 ? `x ${product.unitsper}` : ""}
            </li>
          </ul>
        </h2>
        <ul className="tt-list-parameters">
          <li>
            <div className="tt-price">{CURRENCY + " " + price.toFixed(2)}</div>
          </li>
          <li>
            <div className="detach-quantity-mobile">
              <Quantity product={product} />
            </div>
          </li>
          <li>
            <div className="tt-price SUBTOTAL">
              {CURRENCY + " " + (price * quantity).toFixed(2)}
            </div>
          </li>
        </ul>
      </td>
      <td>
        <div className="tt-price">{CURRENCY + " " + price.toFixed(2)}</div>
      </td>
      <td>
        <div className="detach-quantity-desktop">
          <Quantity product={product} />
        </div>
      </td>
      <td>
        <div className="tt-price SUBTOTAL">
          {CURRENCY + " " + (price * quantity).toFixed(2)}
        </div>
        {product.presentation === "Caja" && (
          <Fragment>
            <div className="tt-add-info">
              {quantity / product.unitsper} x {product.presentation}{" "}
              {product.unitsper !== 1 ? `x ${product.unitsper}` : ""}M
              <sup>2</sup>
            </div>
            <div className="tt-add-info">
              Total: {quantity} M<sup>2</sup>
            </div>
          </Fragment>
        )}
      </td>
    </tr>
  );
};

CartItem.propTypes = {
  product: propType.object.isRequired,
};

export default CartItem;
